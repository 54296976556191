.customCheckButton_container{
    border:1px solid  #ddd;
    height: fit-content;
    border-radius: 10px;
    min-width: 170px;
    display: flex;
}

.customCheckButton_label{
    font-size: 20px;
    color:#000 !important
}
.customCheckButton_labelLarge{
    font-size: 22px;
    font-weight: 600;
    color:#000 !important
}
.customCheckButton_labelSmall{
    font-size: 16px;
    color:#555555 !important

}

.customCheckButton_container  input[type='radio']:checked {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color:#3C65F5;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;  
      
}
.customCheckButton_container  input[type='radio'] {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color:white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #3C65F5;    
}