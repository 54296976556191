.NM_modal{
    margin: 0;
    padding: 0 !important;
}
.NM_modal .modal-dialog{
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    min-width: 100vw;
    overflow: hidden;
}

.NM_modal .modal-content{
    position: absolute;
    right: 0;
    background-color: white;
    height: 100vh;
    width: 350px;
    max-width: 100vw;
    border-radius: 0;
    border:none;
    animation: slideIn 260ms linear;
}

.NM_modal .header{
    display: flex;
    justify-content: space-between;
}

.NM_modal .title{
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    color: #66789C;
}

.NM_modal .title2{
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #05264E;
}

.NM_modal .subtitle{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #66789C;
}

.NM_modal .readAll{
    text-align: end;
    cursor: pointer;
    color:#3C65F5;
    font-weight: 600;
}
.NM_modal .readAll:hover{
    color:#3c64f5dc;
}

@keyframes slideIn {
    0%{
        transform:translateX(100%)
    }
    100%{
        transform:translateX(0%)
    }
}