.changePass_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.changePass_container .blueHeader{
    font-weight: 600;
font-size: 16px;
line-height: 23px;
color: #3C65F5;
}

.changePass_container .header{
    font-weight: 700;
font-size: 25px;
line-height: 23px;
color: #05264E;
}
.changePass_container .input{
border: none;
outline:none;
height: 100%;
}
.changePass_container .label{
    font-weight: 400;
font-size: 16px;
line-height: 23px;
color: #66789C;
}

.changePass_container .inputContainer{
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    height: 50px;
    width: 100%;
}

.changePass_container .password-eye-button{
    border:none
}
.changePass_container .button{
    width: 100%;
    height: 50px;
}