body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  padding: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* auth screeens global css -- Start */

input::placeholder{
  font-weight: lighter;
  color: black !important ;
}
.dim-placeholder::placeholder{
  color:#bbb !important;
  font-weight: 500;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input[type='radio'] {
 border-color:#3c65f5;
}
input[type=number]::-webkit-inner-spin-button {
  opacity: 0
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.modalContainer .modal-content{
  width: fit-content;
}

.modalContainer .modal-dialog{
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-width: 100vw;
  justify-content: center;
}

.staticPages{
  box-sizing: border-box;
}
.horizontal-divider{

  width: 100%;
  height: 1px;
  background-color: #ddd;
}

.vertical-divider{
  height: 100%;
  width: 1px;
  background-color: #ddd;
}
.main_container{
  padding:40px;
  padding-inline: 5vw !important;
  margin:0 !important;  
  min-width: 100% !important;
}
.black_color{
  color: black !important;
}
.dropdown-item-custom{
  cursor: pointer;
}
.dropdown-item-custom:hover{
    background-color: #ddd;
}
.auth_label_title{
  position: relative; 
  z-index: 9 ;
  font-size: 45px;
}
.auth_labelUnderline{
  position: relative;
  height: 15px;
  width: 100px;
  background-color: #d0daff;
  bottom: 0;
  translate: 5px -13px;
  z-index: 0;
}
.cardShadow{
  border: none !important;
  box-shadow: 1px 3px 1px #ddd !important;
  border-radius: 10 !important;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ellipsis-3 {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterModal .modal-content{
  background-color:#eee;
  padding:20px;
  border:none;
  max-height: 95vh;
  overflow-y: auto;
}
.filterModal .modal-dialog{
  background-color:#eee;
  border-radius: 10px;
}

.profileIncomplete{
  position: relative;
  cursor: pointer;
  translate: 48px -25px;
}

.profileIncomplete .tooltip{
  padding: 8px;
  padding-inline: 10px;
  position: absolute; 
  border-radius: 6px;
  display: none;
  background: #E0E6F7;
  border: 0.3px solid #888;
  box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1;
  left: 16px;
  top:20px;
  width: 130px;
  z-index: 999;
  opacity: 1;
}
.profileIncomplete:hover .tooltip{
  display: block;
}

.indicatorNotStaffedJob{
  position: relative;
}
.indicatorNotStaffedJob img{
  margin-inline: 10px;
}
.indicatorNotStaffedJob .tooltip1{
  padding: 5px;
  padding-inline: 10px;
  position: absolute;
  border-radius: 6px;
  display: none;
  background: #E0E6F7;
  border: 0.3px solid #3C65F5;
  box-shadow: 0 0 20px 15px rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 1;
  right: 16px;
  top:20px;
  width: 130px;
  z-index: 999;
}
.indicatorNotStaffedJob:hover .tooltip1{
  display: block;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0,0,0,0.5);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
  padding-left: 5px !important;
  position: absolute;
}

*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.0);
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  border-right: 4px solid rgba(0,0,0,0.2);
}
@media screen and (max-width:992px) {
  .auth_labelUnderline{
    bottom: 5px;
    height: 24px;
    width: 88px;
    translate:6px -25px;
    z-index: 0;
  }
  .auth_label_title{
    font-size: 50px;
  }
}
@media screen and (max-width:550px) {
  .auth_labelUnderline{
    bottom: 5px;
    height: 15px;
    width: 58px;
    translate:4px -14px;
    z-index: 0;
  }
  .auth_label_title{
    font-size: 35px;
  }
}

/* auth screeens global css -- End  */

.password-field{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.password-eye-button {
  background-color: #fff !important;
  border-color: #ccc !important;
  border-width: 1px !important;
  border-left-width: 0 !important;
  color: black !important;
  z-index: 9 !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.password-eye{
  opacity: 0.6;
}
.PhoneInput input{
  border:none;
  outline:none;
  font-size: 18px;
  margin-right: 10px;
}
.blue-date::-webkit-calendar-picker-indicator {
  filter: invert(1)
            brightness(50%)
            sepia(100%)
            saturate(10000%)
            hue-rotate(200deg);
  height: 30px;
  width: 30px;
}
/* Welcome Page  ---- start */
.welcome_header_login_button_fake_button{
  width: 250px;
  cursor:default !important;
}
.welcom_header_button_container{
  height: 43px;
  overflow: hidden;
}
.welcom_header_button_container:hover{

  overflow: visible;
}

@media screen and (max-width:992px) {
  .welcom_header_button_container{
    height: 100px;
    overflow: hidden;
  }
}

.warning-under-verification-text{
  display: flex;
}

@media screen and (max-width:1250px) {
  .warning-under-verification-text{
    display: none;
    position: absolute;
    background-color: #FEF7D1;
    border-Radius: 20px;
    height: 40px;
    align-items: center;
    padding-inline: 10px;
    border: 1px solid #F7C752;
  }
  .warning-under-verification{
    aspect-ratio: 1;
  }
  .warning-under-verification:hover .warning-under-verification-text{
    display: flex;

  }
}


/* Welcome Page  ---- end */
