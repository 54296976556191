.staticPages{
    box-sizing: border-box;
}

.staticPages .header{
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #05264E;
}

.staticPages .title{
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
}

.staticPages .subtitle{
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #66789C;
}