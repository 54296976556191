.applicationCard_container{
    background: #F2F6FD;
border: 1px solid rgba(90, 106, 163, 0.2);
border-radius: 20px;
cursor: pointer;
position: relative;
}
.applicationCard_shadowCard{
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.applicationCard_title{
    font-weight: 700 !important;
font-size: 18px;
color: #05264E;
}
.applicationCard_subtitle{
    font-size: 14px;
line-height: 25px;
color: #66789C;
}
.applicationCard_time{
    font-size: 14px;
line-height: 18px;
color: #A0ABB8;
}
.jobListCard_button:hover{
    background-color: #bbcaf4 !important;
}
.applicationCard_description{
    font-size: 14px;
line-height: 30px;
color: #66789C;
}
.applicationCard_tagContainer{
    display: flex;
    gap:8px;
    flex-wrap: wrap;
}
.applicationCard_cost{
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    
    color: #3C65F5;
}
.applicationCard_costSpan{
    font-weight: 500;
    color:#4F5E64;
    font-size: 16px;
}
.applicationCard_button{
    background: #DAE0EE;
border-radius: 3px;
font-size: 14px;
line-height: 23px;
padding:3px 10px;
border:none;
color: #66789C;
}
.applicationCard_button:hover{
    background: #DAE0EE;
border-radius: 3px;
font-size: 14px;
line-height: 23px;
border:none;
padding:3px 10px;
color: #66789C;
}
.applicationCard_button2{
    background: #CAEED6;
border-radius: 3px;
font-size: 14px;
line-height: 23px;
padding:3px 10px;
border:none;
color: #00A938;
}
.applicationCard_button2:hover{
    background: #CAEED6;
border-radius: 3px;
font-size: 14px;
line-height: 23px;
padding:3px 10px;
border:none;
color: #00A938;
}   
.jobListCard_button3{
    background: #DAE0EE;
border-radius: 3px;
font-size: 14px;
line-height: 23px;
padding:5px 10px;
border:none;
color: #66789C;
}
.jobListCard_button4{
background: #8658F0;
border-radius: 3px;
font-size: 14px;
line-height: 23px;
padding:5px 10px;
border:none;
}
.button_bg{
background: #8658F0;
}
.jobDetails_title2{
    font-weight: 700;
font-size: 25px;
line-height: 30px;
color: #5A6AA3;
}
.jobDetails_card2{
    background: #F2F6FD;
border: 1px solid #E0E6F7;
border-radius: 10px;
}
.jobDetails_subTitle2{
    font-weight: 400;
font-size: 14px;
line-height: 25px;
color: #66789C;
}
.jobDetails_cardButton{
    background: #5A6AA3;
border-radius: 5px;
width: 100%;
}
.jobDetails_cardButton2{
    height: 50px;
    width: 100%;
}
.jobDetails_cardButton3{
    height: 50px;
    border: 1px solid #3C65F5;
border-radius: 5px;
color:#3C65F5;
background-color: white;
}

.jobApplication_invite{
    position: absolute;
    top:10px;
    right: 10px;
    aspect-ratio: 1;
    width: 28px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 135px;
    /* cursor: pointer; */
    z-index: 99;
}
.jobApplication_invite img{
    padding: 5px;
    width: 29px;
}