.createProject_title{
    font-size: 20px;
    color:#05264E;
}

.createProject_input{
    max-width: 500px;
    height: 50px;
}

.createProject_textArea{
    height: 110px;
    min-height: 100px;
    max-height: 200px;
}

.createProject_dateLabel{
    font-weight: 500;
}

.createProject_dateInput{
    height: 50px;
}
.createProject_createButton{
    width: 120px;
    height: 50px;
    font-size: 18px;
}
.createProject_cancelButton{
    width: 120px;
    height: 50px;
    font-size: 18px;
    background-color:white;
    color: #3C65F5;
    border: 1px solid #3C65F5;
}
.createProject_cancelButton:hover{
    width: 120px;
    height: 50px;
    font-size: 18px;
    background-color:rgb(242, 246, 255);
    color: #3C65F5;
    border: 1px solid #3C65F5;
}
.createProject_check{
    font-size: 20px;
    color: #666;
    font-weight: 500;
}

.createProject_header{
    font-weight: 600;
    font-size: 22px;
}