.createJob_card{
    display: flex !important;
    align-items: center;
    min-height:70vh;
}
.createJob_container{
    min-width: min(870px , 75vw);
    max-width: 870px;
    position:relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column  ;
}
.createJob_container2{ 
    width: 100%;
    position:relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column  ;
}
.createJob_contentContainer{
    flex-grow: 1;
}
.createJob_spaceBetween{
    display: flex;
    justify-content: space-between;
}
.createJob_center{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.createJob_container_title{
    font-weight: 600;
    font-size: 24px;
}
.createJob_index{
    font-size:16px ;
    color: #555;
}
.createJob_cancel{
    background-color: white;
    border:1px solid #3C65F5;
    color:#3C65F5;
    height:50px
}
.createJob_cancel:hover{
    background-color: rgb(240, 244, 249);
    border:1px solid #3C65F5;
    color:#3C65F5;
    height:50px
}
.createJob_previous{
    background-color: white;
    border:1px solid #fff;
    color:#000;
    height:50px
}
.createJob_previous:hover{
    background-color: rgb(202, 202, 202);
    border:1px solid #fff;
    color:#000;
    height:50px
}
.createJob_next{
    height:50px
}

.createJob_select{
    border:1px solid #ddd;
    border-radius: 10px;
    background-color:white;
    height: 45px;
    
}
.createJob_header{
    font-weight:600;
    color: #3C65F5;
    font-size: 42px;

}
.createJob_title{
    font-weight:600;
    font-size: 20px;

}

.createJob_edit{
    border-radius: 40px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #ddd;
    cursor: pointer;
}
.createJob_input{
    max-height: 300px;
    height: 140px;
    border-radius: 0;
}

.createJob_skillContainer{
    display: flex;
    max-width: 700px;
    flex-wrap: wrap;
    gap:15px;
    font-size: 17;
}
