.inviteProject_container{
    background-color: #F5F5F5;
    max-height: 90vh;
    position: relative;
}
.inviteProject_title{
    font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;
color: #05264E;
}
.inviteProject_listScroll{
    flex-grow: 1;
    overflow-y: auto;
    max-height: 60vh;
}
.inviteProject_innerContainer{
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}