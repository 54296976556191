.jobs_filterTitle{
    font-weight: 600;
font-size: 17px;
line-height: 21px;

color: #05264E;
}
.job_filterHour{
    font-weight: 400 !important;
font-size: 20px !important;
line-height: 20px;

color: #555555;
}
.job_header{
    display: flex;
    justify-content:center;
    font-weight: 700;
font-size: 25px;
line-height: 38px;
color: #05264E;
gap:10px

}
.job_headerSpan{
    color:#3C65F5
}
.job_search{
    box-shadow: none;
    border: none;
    border-width: 0;
}
.jobListCard_h3{
    font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #05264E;
}
.jobListCard_title{
    font-weight: 700;
font-size: 16px;
line-height: 20px;

color: #05264E;
}
.jobListCard_description{
    font-weight: 500;
font-size: 13px;
line-height: 16px;
color: #4F5E64;
}
.jobListCard_cost{
    font-weight: 700;
font-size: 18px;
line-height: 22px;
color: #3C65F5;
}
.jobListCard_costSpan{
    color:#4F5E64;
    font-size: 16px;
    font-weight: 500    ;
}
.jobListCard_button{
    background: #E0E6F7;
border: 1px solid #E0E6F7;
border-radius: 5px;
font-weight: 400;
font-size: 14px;
line-height: 18px;

color: #3C65F5;
}
.jobListCard_button:hover{
    background: #E0E6F7;
border: 1px solid #E0E6F7;
border-radius: 5px;
font-weight: 400;
font-size: 14px;
line-height: 18px;

color: #3C65F5;
}
.jobListCard_tagContainer{
    display: flex;
    flex-wrap: wrap;
    gap:10px
}
.jobListContainer{
    border-radius: 20px;
}

.job_title{
    font-weight: 600;
font-size: 20px;
line-height: 24px;
color: #05264E;
}
.job_reset{
    font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #5A6AA3;
cursor: pointer;

align-self: center;
}
.job_sortContainer{
    display: flex;
    align-items: center;
    border: 1px solid #3C65F5;
    border-radius: 5px;
    padding-left: 10px;
    color: #777777;
}
.job_select{
    border:none;
    background-color: transparent;
    color:#05264E
}