.jobDetails_primaryButton{
    height: 50;
    border: 1px solid rgba(0, 0, 0, 0.1);
box-shadow: 0px 15px 15px rgba(60, 101, 245, 0.3);
border-radius: 5px;
}
.jobDetails_secondaryButton{
    height: 50;
    background-color: white;
    color:#000;
    border: 1px solid rgba(0, 0, 0, 0.1);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
border-radius: 5px;
}

.jobDetails_title{
    font-weight: 700;
font-size: 40px;
line-height: 50px;
color: #05264E;
}
.jobDetails_heading{
    font-weight: 600;
    font-size: 25px;
    color: #05264E;
}
.jobDetails_details{
    font-weight: 500;
font-size: 18px;
line-height: 30px;
color: #A0ABB8;
}
.jobDetails_label{
    font-weight: 700;
font-size: 20px;
line-height: 25px;

color: #A0ABB8;
}
.jobDetails_labelSpan{
    font-weight: 700;
font-size: 20px;
line-height: 25px;

color: #3C65F5;
}
.jobDetails_headingSmall{
    font-weight: 700;
font-size: 20px;
line-height: 25px;

color: #05264E;
}
.jobDetails_text{
    font-weight: 600;
font-size: 20px;
}
.jobDetails_blueText{
    font-weight: 500;
font-size: 20px;
line-height: 25px;

color: #66789C;

}
.jobApplications_topBar{
    border:1px solid #ddd;
    border-width: 0;
    border-bottom-width: 1px;
    display: flex;
}
.job_topNav{
    font-size: 18px;
line-height: 22px;
text-align: center;
padding:15px;
cursor: pointer;
}

.job_topNav_active{
    
    color: #3C65F5;
    border:0px solid  #3C65F5;
    border-bottom-width: 3px;
}

.job_applicationContainer{
    display: flex;
    gap:10px;
    flex-wrap: wrap;
}
.applicationDetails_title{
    font-weight: 700;
font-size: 25px;
line-height: 32px;
color: #05264E;
}

.applicationDetails_subtitle{
    font-weight: 400;
font-size: 18px;
line-height: 23px;
color: #66789C;
}
.applicationDetails_span{
    color:#05264E;
    font-size: 18px;
    font-weight: 700;
}
.applicationDetails_heading{
    font-weight: 400;
font-size: 20px;
line-height: 23px;
color: #66789C;
}
.applicationDetails_h2{
    font-size: 25px;
    line-height: 32px;
    color: #05264E;
    
}
.applicationDetails_text{
    font-size: 16px;
line-height: 30px;
color: #66789C;
}

.applicationDetails_button2{
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    background: #00BA3E;
border-radius: 3px;
border:none
}
.applicationDetails_button2:hover{
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    background: #00BA3E;
border-radius: 3px;
border:none
}  
.applicationDetails_button{
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    background: #05264E;
border-radius: 3px;
border:none
}
.applicationDetails_button:hover{
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    background: #05264E;
border-radius: 3px;
border:none
}
.jobDetails_items{
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #66789C;
}
