.breadCrumbs{
    background-color: white;
    z-index: 99;
    padding-inline: 5vw;
    display: flex;
    gap:15px
}

.breadCrumbs .active{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3C65F5;
    cursor: pointer;
}

.breadCrumbs .unActive{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #66789C;
}
.breadCrumbs img{
    filter: invert();
    opacity: 0.6;
}